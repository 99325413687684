const API_PATH = {
  ITEM: {
    ITEM: '/item',
  },
  OPTIONS: {
    FACTORY: '/options/factory',
  },
};

export default API_PATH;
