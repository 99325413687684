import { Theme } from '@mui/material/styles';

/**
 *
 * @param  theme テーマ
 * @return style
 */
export const Container = (theme: Theme) => {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {},
      },
    },
  };
};
