import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { optionsAPI } from '../api/optionsAPI';
import { GENERAL_ERROR_MESSAGE } from 'src/constants';
import getAsyncErrorMessage from 'src/utils/getAsyncErrorMessage';
import type { AxiosError } from 'axios';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { OptionsState } from 'src/@types/options';
import type { RejectedWithValueAction } from 'src/@types/common';
import type { AppThunk } from 'src/redux/store';

export const initialState: OptionsState = {
  isLoading: false,
  hasError: false,
  message: undefined,
  factories: undefined,
};

const slice = createSlice({
  name: 'Options',
  initialState,
  reducers: {
    startLoading(state: OptionsState): void {
      state.isLoading = true;
    },

    showMessage(state: OptionsState, action: PayloadAction<string>): void {
      state.isLoading = false;
      state.hasError = false;
      state.message = action.payload;
    },

    hasError(state: OptionsState, action: PayloadAction<string>): void {
      state.isLoading = false;
      state.hasError = true;
      state.message = action.payload;
    },

    resetMessage(state: OptionsState): void {
      state.isLoading = false;
      state.hasError = false;
      state.message = undefined;
    },
  },

  extraReducers: (builder) => {
    builder
      // Get Factories
      // ----------------------------------------
      .addCase(getOptionsFactories.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getOptionsFactories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.message = undefined;
        state.factories = action.payload;
      })
      .addCase(getOptionsFactories.rejected, (state, action) => {
        const rejectedWithValueAction = action as RejectedWithValueAction<undefined, string>;
        const message: string = rejectedWithValueAction.payload ?? action.error ?? GENERAL_ERROR_MESSAGE;
        state.isLoading = false;
        state.hasError = true;
        state.message = message;
      });
  },
});

/**
 *
 * @param  message メッセージ
 * @return void
 */
export const showMessage =
  (message: string): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.showMessage(message));
  };
/**
 *
 * @param  message メッセージ
 * @return void
 */
export const showError =
  (message: string): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.hasError(message));
  };
/**
 *
 * @return void
 */
export const resetMessage =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.resetMessage());
  };

export const getOptionsFactories = createAsyncThunk('Options/getOptionsFactories', async (_, thunkAPI) => {
  try {
    const response = await optionsAPI.getOptionsFactories();
    return response.data;
  } catch (err) {
    const message: string = getAsyncErrorMessage(err as AxiosError | Error);
    return thunkAPI.rejectWithValue(message);
  }
});

export const { reducer } = slice;
