import i18n from 'src/locales/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import { OVERVIEW_PATH } from './overviewPath';

// サイドバーの描画
export const overviewBar = {
  subheader: i18n.t('sidemenu.overview.title'),
  items: [
    {
      title: i18n.t('sidemenu.overview.links'),
      path: OVERVIEW_PATH.LINKS,
      icon: <FontAwesomeIcon icon={faListCheck} size="lg" />,
    },
  ],
};
