import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------
/**
 *
 * @param  theme テーマ
 * @return style
 */
export const Menu = (theme: Theme) => {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },
        },
      },
    },
  };
};
