import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { snowflakeApi } from 'src/api/snowflakeAPI';
import useAgGridTheme from 'src/hooks/useAgGridTheme';
import getAsyncErrorMessage from 'src/utils/getAsyncErrorMessage';

import * as Yup from 'yup';

type InitialValues = {
  account: string;
  username: string;
  password: string;
  warehouse: string | null;
  role: string | null;
  database: string | null;
  schema: string | null;
  table: string | null;
};

export const useSnowflakePageModel = () => {
  const agGridTheme = useAgGridTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [datas, setDatas] = useState<any[]>([]);
  const [account, setAccount] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const [warehouse, setWarehouse] = useState<string | null>(null);
  const [role, setRole] = useState<string | null>(null);
  const [database, setDatabase] = useState<string | null>(null);
  const [schema, setSchema] = useState<string | null>(null);
  const [table, setTable] = useState<string | null>(null);

  const handleShowPassword = useCallback(() => {
    setShowPassword((show) => !show);
  }, [showPassword]);

  const getDatas = useCallback(async () => {
    const datas = await snowflakeApi
      .getTableDatas({
        account,
        username,
        password,
        warehouse,
        role,
        database,
        schema,
        table,
      })
      .catch((err) => {
        const message: string = getAsyncErrorMessage(err as AxiosError | Error);
        enqueueSnackbar(`エラー : ${message}`, { variant: 'error' });
        return [];
      });

    setDatas(datas);
  }, [account, username, password, warehouse, role, database, schema, table]);

  // Formik

  const validation = () => {
    return Yup.object().shape({
      account: Yup.string().required('Account is required'),
      username: Yup.string().required('Username is required'),
      password: Yup.string().required('Password is required'),
    });
  };

  const formik = useFormik<InitialValues>({
    initialValues: {
      account,
      username,
      password,
      warehouse,
      role,
      database,
      schema,
      table,
    },
    enableReinitialize: true,
    validationSchema: validation,
    validateOnChange: true,
    onSubmit: async (_) => {
      getDatas();
    },
  });

  const { errors, touched, handleSubmit } = formik;

  return {
    agGridTheme,
    account,
    username,
    password,
    showPassword,
    warehouse,
    role,
    database,
    schema,
    table,
    datas,
    setAccount,
    setUsername,
    setPassword,
    handleShowPassword,
    setWarehouse,
    setRole,
    setDatabase,
    setSchema,
    setTable,
    formik,
    handleSubmit,
    errors,
    touched,
  };
};
