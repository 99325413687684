import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------
/**
 *
 * @param  theme テーマ
 * @return style
 */
export const Radio = (theme: Theme) => {
  return {
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
  };
};
