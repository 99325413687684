import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GENERAL_ERROR_MESSAGE, GENERAL_SUCCESS_MESSAGE } from 'src/constants';
import getAsyncErrorMessage from 'src/utils/getAsyncErrorMessage';
import type { AxiosError } from 'axios';
import type { RejectedWithValueAction } from 'src/@types/common';
import type { AppThunk, RootState } from 'src/redux/store';
import { ExcelDatas, ExcelState } from 'src/@types/excel';
import { excelAPI } from 'src/api/excelAPI';
import fileSaver from 'file-saver';
import { Buffer } from 'buffer';

const initialState: ExcelState = {
  isLoading: false,
  hasError: false,
  message: undefined,
  datas: [],
};

const slice = createSlice({
  name: 'Excel',
  initialState,
  reducers: {
    resetMessage(state: ExcelState): void {
      state.isLoading = false;
      state.hasError = false;
      state.message = undefined;
    },
  },

  extraReducers: (builder) => {
    builder
      // Upload Excel
      // ----------------------------------------
      .addCase(uploadExcel.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(uploadExcel.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.message = GENERAL_SUCCESS_MESSAGE;
        state.datas = action.payload;
      })
      .addCase(uploadExcel.rejected, (state, action) => {
        const rejectedWithValueAction = action as RejectedWithValueAction<FormData, string>;
        const message: string = rejectedWithValueAction.payload ?? action.error ?? GENERAL_ERROR_MESSAGE;
        state.isLoading = false;
        state.hasError = true;
        state.message = message;
        state.datas = [];
      })
      // Download Excel
      // ----------------------------------------
      .addCase(downloadSample.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(downloadSample.fulfilled, (state, _) => {
        state.isLoading = false;
        state.hasError = false;
        state.message = GENERAL_SUCCESS_MESSAGE;
      })
      .addCase(downloadSample.rejected, (state, action) => {
        const rejectedWithValueAction = action as RejectedWithValueAction<undefined, string>;
        const message: string = rejectedWithValueAction.payload ?? action.error ?? GENERAL_ERROR_MESSAGE;
        state.isLoading = false;
        state.hasError = true;
        state.message = message;
      });
  },
});

/**
 *
 * @return void
 */
export const resetMessage =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.resetMessage());
  };

export const uploadExcel = createAsyncThunk<ExcelDatas[], FormData, { state: RootState }>('Excel/uploadExcel', async (params, thunkAPI) => {
  try {
    const response = await excelAPI.uploadExcel(params as FormData);
    return response.data;
  } catch (err) {
    const message: string = getAsyncErrorMessage(err as AxiosError | Error);
    return thunkAPI.rejectWithValue(message);
  }
});

export const downloadSample = createAsyncThunk<void, undefined, { state: RootState }>('Excel/downloadSample', async (_, thunkAPI) => {
  try {
    const response = await excelAPI.downloadSample();
    const { buffer, filename, fileType } = response.data;
    const bufferData = Buffer.from(buffer as Buffer);
    const blobData = new Blob([bufferData], { type: fileType });
    fileSaver(blobData, filename);
  } catch (err) {
    const message: string = getAsyncErrorMessage(err as AxiosError | Error);
    return thunkAPI.rejectWithValue(message);
  }
});

export const { reducer } = slice;
