import React from 'react';
import { Backdrop, Checkbox, CircularProgress, Container, Grid, Stack, TextField, Typography } from '@mui/material';

import { Page } from 'src/components-minimals/Page';

import { useProxyTimeoutPageModel } from './useProxyTimeoutPageModel';
import { HeaderBreadcrumbs } from 'src/components-minimals/HeaderBreadcrumbs';
import { Form, FormikProvider } from 'formik';
import { LoadingButton } from '@mui/lab';

const ProxyTimeoutPage = (): React.ReactElement => {
  const pm = useProxyTimeoutPageModel();
  return (
    <Page title={'プロキシタイムアウト対応'}>
      <Container maxWidth="xl">
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <HeaderBreadcrumbs
              heading={'プロキシタイムアウト対応'}
              links={[
                { name: 'proxy', href: '/proxy' },
                { name: 'proxy-timeout', href: '/proxy/timeout' },
              ]}
            />
          </Grid>
        </Grid>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={pm.isLoading}>
          <Stack direction="column" spacing={1}>
            <Stack direction="row" justifyContent={'center'}>
              <CircularProgress color="inherit" />
            </Stack>
            <Stack direction="column" justifyContent={'center'} style={{ whiteSpace: 'pre-line' }}>
              <Typography variant="subtitle2" textAlign={'center'} children={pm.message} />
            </Stack>
          </Stack>
        </Backdrop>
        <Grid>
          <Typography variant="body2">プロキシによってタイムアウトしないようコーディングされているサンプルです。</Typography>
          <Typography variant="body2">サーバーとの通信時間が長くなるとプロキシによって通信が遮断されるためそれを回避します。</Typography>
          <Typography variant="body2">通信時間を任意の時間に設定し長時間の通信でもエラーにならないことをテストします。</Typography>
        </Grid>
        <FormikProvider value={pm.formik}>
          <Form autoComplete="off" noValidate>
            <Grid container mt={3} direction="column">
              <Typography variant="body2">サーバーとの通信時間</Typography>
              <Grid width={'220px'} mt={2}>
                <TextField
                  label="秒"
                  value={pm.second.value}
                  onChange={(e) => pm.second.setter(e.target.value)}
                  error={Boolean(pm.touched.second && pm.errors.second)}
                  helperText={Boolean(pm.touched.second && pm.errors.second) ? pm.errors.second : '整数で入力してください。'}
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid mt={2} item container direction={'row'} spacing={2} style={{ paddingLeft: '6px' }}>
                <Grid>
                  <Checkbox checked={pm.check.value} onChange={pm.check.onChange} />
                </Grid>
                <Grid alignContent={'center'}>
                  <Typography variant="body2">バックエンドから定期的に通信</Typography>
                </Grid>
              </Grid>
              <Grid width={'220px'} mt={2}>
                <LoadingButton loading={pm.isLoading} variant="contained" color="primary" fullWidth onClick={() => pm.handleButtonClick('fetch')}>
                  送信（fetch）
                </LoadingButton>
              </Grid>
              <Grid width={'220px'} mt={2}>
                <LoadingButton loading={pm.isLoading} variant="contained" color="secondary" fullWidth onClick={() => pm.handleButtonClick('axios')}>
                  送信（axios）
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
};
export default ProxyTimeoutPage;
