import { useContext } from 'react';
import { SettingsContext } from '../contexts/SettingsContext';

/**
 *
 * @return コンテキスト
 */
const useSettings = () => useContext(SettingsContext);

export default useSettings;
