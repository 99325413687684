import { Suspense } from 'react';
import { LoadingScreen } from 'src/components-minimals/LoadingScreen';
import { useLocation } from 'react-router-dom';

export const loadingComponent = (Component: any) => (props: any) => {
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }>
      <Component {...props} />
    </Suspense>
  );
};
