import React, { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import { LogoOnlyLayout } from 'src/components-mars/layouts/LogoOnlyLayout';
// guards
import GuestGuard from 'src/guards/GuestGuard';
import AuthGuardLayout from 'src/components-mars/layouts/AuthGuardLayout';
// paths
import { INITIAL_PATH } from 'src/constants';
import { masterRoute } from 'src/features/master/routes';
import { portalRoute } from 'src/features/portal/routes';
import { excelRoute } from 'src/features/excel/routes';
import { overviewRoute } from 'src/features/overview/routes';
// components
import { LoadingScreen } from 'src/components-minimals/LoadingScreen';
import { snowflakeRoute } from './pages-route/SnowflakePageRoute';
import { proxyRoute } from './pages-route/ProxyPageRoute';

/**
 *
 * @param  Component コンポーネント
 * @return コンポーネント
 */
const Loadable = (Component: any) => (props: any) => {
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');
  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }>
      <Component {...props} />
    </Suspense>
  );
};
const Login = Loadable(lazy(() => import('src/features/authentication/Login')));
const ResetPassword = Loadable(lazy(() => import('src/features/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('src/features/authentication/VerifyCode')));
const Page500 = Loadable(lazy(() => import('src/features/errors/Page500')));
const NotFound = Loadable(lazy(() => import('src/features/errors/Page404')));

/**
 *
 * @return ルーター
 */
export const Router = () => {
  return useRoutes([
    {
      path: '/',
      element: <AuthGuardLayout />,
      children: [
        {
          path: '/',
          element: <Navigate to={INITIAL_PATH} replace />,
        },
      ],
    },

    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Feature Routes
    overviewRoute,
    masterRoute,
    excelRoute,
    proxyRoute,
    portalRoute,
    snowflakeRoute,

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
};
