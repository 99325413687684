import { apiGateway, getUser } from 'src/config';
import axiosInstance from 'src/utils/axios';
import type { AxiosResponse } from 'axios';
import type { PortalUser } from 'src/@types/portalUser';

export const userAPI = {
  /**
   * ユーザ一覧の取得
   * @return コンポーネント
   */
  getUsers() {
    return axiosInstance.get<undefined, AxiosResponse<PortalUser[]>>(apiGateway + getUser);
  },
};
