import axios from 'axios';
import { Auth } from 'aws-amplify';
import type { AxiosRequestConfig } from 'axios';


const axiosInstance = axios.create({
  timeout: 60000,
});

axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
  // console.log(config);
  if(config.headers !== undefined) {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    if(token) {
      config.headers.Authorization = 'Bearer ' + token;
    }
  }
  return config;
});

export default axiosInstance;
