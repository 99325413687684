import { Navigate } from 'react-router-dom';
import AuthGuardLayout from 'src/components-mars/layouts/AuthGuardLayout';

import { MASTER_PATH } from './masterPath';
import { lazy } from 'react';
import { RoleBasedGuard } from 'src/guards/RoleBasedGuard';
import { loadingComponent } from 'src/components-mars/effect/LoadingComponent';
import { USER_ROLES } from 'src/constants';

const ItemListPage = loadingComponent(lazy(() => import('src/pages/item-list-page/ItemListPage')));
const ItemEditPage = loadingComponent(lazy(() => import('src/pages/item-edit-page/ItemEditPage')));

export const itemRoute = {
  path: 'item',
  children: [
    {
      path: '',
      element: (
        <RoleBasedGuard accessibleRoles={[USER_ROLES.SCAFFOLD_POWERUSER, USER_ROLES.SCAFFOLD_ADMIN]}>
          <ItemListPage />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'create',
      element: (
        <RoleBasedGuard accessibleRoles={[USER_ROLES.SCAFFOLD_POWERUSER, USER_ROLES.SCAFFOLD_ADMIN]}>
          <ItemEditPage />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'update/:id',
      element: (
        <RoleBasedGuard accessibleRoles={[USER_ROLES.SCAFFOLD_POWERUSER, USER_ROLES.SCAFFOLD_ADMIN]}>
          <ItemEditPage />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'delete/:id',
      element: (
        <RoleBasedGuard accessibleRoles={[USER_ROLES.SCAFFOLD_POWERUSER, USER_ROLES.SCAFFOLD_ADMIN]}>
          <ItemEditPage />
        </RoleBasedGuard>
      ),
    },
  ],
};

export const masterRoute = {
  path: 'master',
  element: <AuthGuardLayout />,
  children: [
    {
      path: '',
      element: <Navigate to={MASTER_PATH.ITEM} replace />,
    },
    itemRoute,
  ],
};
