import { apiGateway } from 'src/config';
import axios from 'src/utils/axios';

export const snowflakeApi = {
  /**
   * snowflakeのサンプル
   * @param param database name, schema name
   * @returns 答え
   */
  getTableDatas: async (param: {
    account: string;
    username: string;
    password: string;
    warehouse: string | null;
    role: string | null;
    database: string | null;
    schema: string | null;
    table: string | null;
  }): Promise<any[]> => {
    // どのテーブルでも返ってくるので、形の定義ができなく、anyで対応します。
    return await axios.post(apiGateway + '/snowflake/datas', param).then((res) => res.data);
  },
};
