import { apiGateway } from 'src/config';
import { Auth } from 'aws-amplify';
import axiosInstance from 'src/utils/axios';
import axios from 'axios';

export const proxyApi = {
  /**
   * proxyのサンプル
   * @param timeout timeout
   * @returns 答え
   */
  streamTimeoutFetch: async function* (timeout: number): AsyncGenerator<any> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const response = await fetch(apiGateway + `/proxy/streamTimeout?timeout=${timeout}`, { headers });
    if (!response.ok) {
      throw new StreamError(response.status, response.statusText);
    }
    const reader = response.body?.getReader();
    if (!reader) {
      throw new StreamError(0, 'Failed to get reader.');
    }
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      const text = new TextDecoder().decode(value);
      yield text;
    }
  },
  defaultFetch: async (timeout: number) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    await fetch(apiGateway + `/proxy/timeout?timeout=${timeout}`, { headers });
  },
  streamTimeoutAxios: async function (timeout: number): Promise<any> {
    try {
      const response = await axiosInstance.get(apiGateway + '/proxy/streamTimeout', {
        params: { timeout },
        timeout: 30 * 60 * 1000,
      });
      return response;

      // axios not support stream in browser ? https://github.com/axios/axios/issues/479#issuecomment-254114656
      // https://stackoverflow.com/questions/60048180/is-it-possible-to-post-a-responsetype-stream-in-axios
    } catch (error) {
      if (axios.isAxiosError(error)) throw new StreamError(error.response?.status || 0, error.response?.statusText || 'Unknown Error');
      else throw new StreamError(0, 'Unknown Error');
    }
  },
  defaultAxios: async (timeout: number) => {
    await axiosInstance.get(apiGateway + '/proxy/timeout', {
      params: { timeout },
    });
  },
};

/**
 * When an error occurs
 */
class StreamError extends Error {
  /**
   *
   * @param code error code
   * @param message error message
   */
  constructor(
    public code: number,
    public message: string,
  ) {
    super(message);
  }
}
