import i18n from 'src/locales/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { EXCEL_PATH } from './excelPath';

// サイドバーの描画
export const excelBar = {
  subheader: i18n.t('sidemenu.excel.title'),
  items: [
    {
      title: i18n.t('sidemenu.excel.upload'),
      path: EXCEL_PATH.UPLOAD,
      icon: <FontAwesomeIcon icon={faFileExcel} size="lg" />,
    },
  ],
};
