import { Navigate } from 'react-router-dom';
import AuthGuardLayout from 'src/components-mars/layouts/AuthGuardLayout';

import { EXCEL_PATH } from './excelPath';
import { RoleBasedGuard } from 'src/guards/RoleBasedGuard';
import { USER_ROLES } from 'src/constants';
import { loadingComponent } from 'src/components-mars/effect/LoadingComponent';
import { lazy } from 'react';

const UploadExcelMain = loadingComponent(lazy(() => import('src/pages/excel-page/ExcelPage')));

export const excelRoute = {
  path: 'excel',
  element: <AuthGuardLayout />,
  children: [
    {
      path: '',
      element: <Navigate to={EXCEL_PATH.UPLOAD} replace />,
    },
    {
      path: 'upload',
      children: [
        {
          path: '',
          element: (
            <RoleBasedGuard accessibleRoles={[USER_ROLES.SCAFFOLD_POWERUSER, USER_ROLES.SCAFFOLD_ADMIN]}>
              <UploadExcelMain />
            </RoleBasedGuard>
          ),
        },
      ],
    },
  ],
};
