// hooks
import useAuth from 'src/hooks/useAuth';
// utils
import { createAvatar } from 'src/utils/createAvatar';
//
import Avatar, { Props as AvatarProps } from './Avatar';

// ----------------------------------------------------------------------
/**
 *
 * @param  param0 パラメータ
 * @return コンポーネント
 */
export const MyAvatar = ({ ...other }: AvatarProps) => {
  const { user } = useAuth();

  return (
    <Avatar src={user?.photoURL} alt={user?.displayName} color={user?.photoURL ? 'default' : createAvatar(user?.displayName).color} {...other}>
      {createAvatar(user?.displayName).name}
    </Avatar>
  );
};
