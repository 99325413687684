import { matchPath } from 'react-router-dom';

// ----------------------------------------------------------------------

export { default as NavSectionVertical } from './vertical';
export { default as NavSectionHorizontal } from './horizontal';

/**
 *
 * @param  path パス
 * @return 結果
 */
export const isExternalLink = (path: string) => {
  return path.includes('http');
};
/**
 *
 * @param  path パス
 * @param  pathname パス名
 * @return 結果
 */
export const getActive = (path: string, pathname: string) => {
  return path ? !!matchPath({ path: path, end: false }, pathname) : false;
};
