import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { TextField, Alert, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

import { INITIAL_PATH } from 'src/constants';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

type InitialValues = {
  password: string;
  reenterPassword: string;
  afterSubmit?: string;
};

type UpdatePasswordFormProps = {
  onUser: any;
};
/**
 *
 * @param  param0 パスワード変更properties
 * @return   パスワード変更フォーム
 */
export const UpdatePasswordForm = ({ onUser }: UpdatePasswordFormProps) => {
  const { updatePassword } = useAuth();
  const isMountedRef = useIsMountedRef();
  const navigate = useNavigate();

  const UpdateSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .max(32, 'Enter within 32 characters')
      .min(8, 'Enter at least 8 characters.')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{0,}$/g,
        'Enter one or more uppercase, lowercase, number, and special character.',
      ),
    reenterPassword: Yup.string().oneOf([Yup.ref('password'), null], 'New password does not match'),
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      password: '',
      reenterPassword: '',
    },
    validationSchema: UpdateSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      if (values.password !== values.reenterPassword) {
        setErrors({ afterSubmit: 'New password and reenter new password do not match.' });
        setSubmitting(false);
        return;
      }
      try {
        await updatePassword(onUser, values.password);
        if (isMountedRef.current) {
          setSubmitting(false);
          navigate(INITIAL_PATH, { replace: true });
        }
      } catch (error: any) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <TextField
            fullWidth
            {...getFieldProps('password')}
            type="password"
            label="New Password"
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <TextField
            fullWidth
            {...getFieldProps('reenterPassword')}
            type="password"
            label="Reenter New Password"
            error={Boolean(touched.reenterPassword && errors.reenterPassword)}
            helperText={touched.reenterPassword && errors.reenterPassword}
          />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Save
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
};
