import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'src/redux/store';

export interface LoginState {
  isLoading: boolean;
}

export const initialState: LoginState = {
  isLoading: false,
};

const slice = createSlice({
  name: 'LoginStat',
  initialState,
  reducers: {
    startLoading(state: LoginState): void {
      state.isLoading = true;
    },

    stopLoading(state: LoginState): void {
      state.isLoading = false;
    },
  },
});

/**
 *
 * @return void
 */
export const startLoading =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.startLoading());
  };

/**
 *
 * @return void
 */
export const stopLoading =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.stopLoading());
  };

export default slice;
export const { reducer, actions } = slice;
