import { apiGateway } from 'src/config';
import axiosInstance from 'src/utils/axios';
import API_PATH from 'src/api/endpoints';
import type { AxiosResponse } from 'axios';
import type { Item, CreateItemParams, GetItemParams, GetItemsParams, UpdateItemParams, DeleteItemParams } from 'src/@types/item';

export const itemAPI = {
  /**
   * 商品一覧の取得
   * @param  params GetItemsParams
   * @return Promise<AxiosResponse<Item[]>>
   */
  getItems(params: GetItemsParams): Promise<AxiosResponse<Item[]>> {
    return axiosInstance.get(apiGateway + API_PATH.ITEM.ITEM, { params: { filter: params } });
  },

  /**
   * 商品の取得
   * @param  params GetItemParams
   * @return Promise<AxiosResponse<Item>>
   */
  getItem(params: GetItemParams): Promise<AxiosResponse<Item>> {
    const { code } = params;
    return axiosInstance.get(apiGateway + API_PATH.ITEM.ITEM + '/' + code);
  },

  /**
   * 商品の作成
   * @param  params CreateItemParams
   * @return Promise<AxiosResponse<Item>>
   */
  createItem: async (params: CreateItemParams): Promise<AxiosResponse<Item>> => {
    return axiosInstance.post(apiGateway + API_PATH.ITEM.ITEM, params);
  },

  /**
   * 商品の更新
   * @param  params UpdateItemParams
   * @return Promise<AxiosResponse<Item>>
   */
  updateItem: async (params: UpdateItemParams): Promise<AxiosResponse<Item>> => {
    const { code, update } = params;
    return axiosInstance.put(apiGateway + API_PATH.ITEM.ITEM + '/' + code, update);
  },

  /**
   * 商品の削除
   * @param  params DeleteItemParams
   * @return Promise<AxiosResponse<Item>>
   */
  deleteItem: async (params: DeleteItemParams): Promise<AxiosResponse<Item>> => {
    const { code } = params;
    return axiosInstance.delete(apiGateway + API_PATH.ITEM.ITEM + '/' + code);
  },
};

export default itemAPI;
