/**
 *
 * @param  root ルート
 * @param  sublink サブリンク
 * @return パス
 */
const path = (root: string, sublink: string) => {
  return `${root}${sublink}`;
};

const ROOTS_AUTH = '/auth';
const ROOTS_OVERVIEW = '/overview';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_PROXY = '/proxy';
const ROOTS_MASTER = '/master';
const ROOTS_SNOWFLAKE = '/snowflake';

// ----------------------------------------------------------------------
export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  account: path(ROOTS_DASHBOARD, '/account'),
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_MASTER = {
  root: ROOTS_MASTER,
  item: path(ROOTS_MASTER, '/item'),
};

export const PATH_PROXY = {
  root: ROOTS_PROXY,
  timeout: path(ROOTS_PROXY, '/timeout'),
};

export const PATH_OVERVIEW = {
  root: ROOTS_OVERVIEW,
  links: path(ROOTS_OVERVIEW, '/links'),
};

export const PATH_SNOWFLAKE = {
  root: ROOTS_SNOWFLAKE,
  links: path(ROOTS_SNOWFLAKE, '/list'),
};
