// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, Stack, Container, Typography, Chip } from '@mui/material';
// components
import { Page } from 'src/components-minimals/Page';
import { LoginForm } from 'src/components-minimals/authentication/login';
import { UpdatePasswordForm } from 'src/components-minimals/authentication/update-password';
import { useState } from 'react';
import { currentEnv } from 'src/config';
import { Logo } from 'src/components-minimals/Logo';
import 'src/css/font.css';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
/**
 *
 * @return コンポーネント
 */
export const Login = () => {
  const [user, setUser] = useState(null);
  return (
    <RootStyle title="Login | Mars Scaffold">
      <Container maxWidth="sm">
        <ContentStyle>
          {currentEnv !== 'production' ? (
            <>
              <Stack direction="row" alignItems="center" sx={{ mb: 12 }}>
                {' '}
                <Chip
                  style={{
                    flexGrow: 1,
                    // marginRight: '30px',
                    // paddingLeft: '60px',
                    // paddingRight: '60px',
                    // background: '#F848B5',
                    // color: '#F848B5',
                    width: '50%',
                    fontWeight: 'bold',
                  }}
                  label={'この環境は ' + currentEnv + ' です。'}
                  color="primary"
                  variant="outlined"
                />
                {/* <Box style={{ height: '100px' }}></Box> */}
              </Stack>
            </>
          ) : (
            <></>
          )}
          {/* <Paper sx={{ padding: '25px' }}> */}
          {!user ? (
            <>
              <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
                <Box sx={{ flexGrow: 1 }} alignItems="center">
                  <Stack sx={{ alignItems: 'center' }}>
                    <Logo sx={{ width: '100px', height: '100px' }} />
                    <Typography
                      className="marsFont"
                      style={{
                        // paddingLeft: '16px',
                        // paddingTop: '-20px',
                        paddingBottom: '0px',
                        fontSize: '35px',
                        fontWeight: '900',
                        fontStyle: 'normal',
                        // color: PRIMARY_DARK,
                        // ...(!isDesktop && { marginRight: '30px' }),
                      }}>
                      Mars Scaffold
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>Mars Scaffold </Typography>
                  </Stack>
                </Box>
                {/* <Tooltip title={capitalCase(method)}>
              <Box component="img" src={`/static/auth/ic_${method}.png`} sx={{ width: 32, height: 32 }} />
            </Tooltip> */}
              </Stack>

              {/* <Alert severity="info" sx={{ mb: 3 }}>
            Use email : <strong>demo@minimals.cc</strong> / password :<strong>&nbsp;demo1234</strong>
          </Alert> */}

              <LoginForm onGetUser={(value) => setUser(value)} />
            </>
          ) : (
            <>
              <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h4" gutterBottom>
                    Update your password
                  </Typography>
                </Box>
              </Stack>
              <UpdatePasswordForm onUser={user} />
            </>
          )}
          <Box style={{ height: '200px' }}></Box>
          {/* </Paper> */}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
};
export default Login;
