import { useFormik } from 'formik';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { proxyApi } from 'src/api/proxyAPI';

import * as Yup from 'yup';

type InitialValues = {
  second: string;
};

export const useProxyTimeoutPageModel = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [second, setSecond] = useState<string>('');
  const [check, setCheck] = useState<boolean>(true);
  const [version, setVersion] = useState<'fetch' | 'axios'>('fetch');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const checkHandler = () => {
    setCheck((value) => !value);
  };

  // Formik
  const validation = () => {
    return Yup.object().shape({
      second: Yup.string()
        .required('必須です。')
        .matches(/^[1-9][0-9]*$/, '1以上の整数を入力してください。'),
    });
  };

  const formik = useFormik<InitialValues>({
    initialValues: {
      second,
    },
    enableReinitialize: true,
    validationSchema: validation,
    validateOnChange: true,
    onSubmit: async (_) => {
      if (check) {
        if (version === 'fetch') await streamTimeoutFetch();
        if (version === 'axios') await streamTimeoutAxios();
      } else {
        if (version === 'fetch') await defaultFetch();
        if (version === 'axios') await defaultAxios();
      }
    },
  });

  const { errors, touched, handleSubmit } = formik;

  const handleButtonClick = (type: 'fetch' | 'axios') => {
    setVersion(type);
    handleSubmit();
  };

  const successHandler = (value: any) => {
    if (value === 'END') {
      enqueueSnackbar(`成功しました。`, { variant: 'success', persist: true });
      setMessage('');
    } else setMessage(`${value}秒経過しました。`);
  };

  const errorHandler = (e: any) => {
    enqueueSnackbar(`エラー ${e.messgage}`, { variant: 'error', persist: true });
    setMessage('');
  };

  const streamTimeoutFetch = async () => {
    setIsLoading(true);
    try {
      for await (const msg of proxyApi.streamTimeoutFetch(parseInt(second))) {
        successHandler(msg);
      }
    } catch (e) {
      errorHandler(e);
    } finally {
      setIsLoading(false);
    }
  };

  const defaultFetch = async () => {
    setIsLoading(true);
    try {
      await proxyApi.defaultFetch(parseInt(second));
    } catch (e) {
      errorHandler(e);
    } finally {
      setIsLoading(false);
    }
  };

  const defaultAxios = async () => {
    setIsLoading(true);
    try {
      await proxyApi.defaultAxios(parseInt(second));
    } catch (e) {
      errorHandler(e);
    } finally {
      setIsLoading(false);
    }
  };

  const streamTimeoutAxios = async () => {
    setIsLoading(true);
    await proxyApi
      .streamTimeoutAxios(parseInt(second))
      .then(() => successHandler('END'))
      .catch((e) => {
        console.log(e);
        errorHandler(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    check: {
      value: check,
      onChange: checkHandler,
    },
    second: {
      value: second,
      setter: setSecond,
    },
    formik,
    handleButtonClick,
    errors,
    touched,
    isLoading,
    message,
  };
};
