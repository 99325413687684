import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import getAsyncErrorMessage from 'src/utils/getAsyncErrorMessage';
import { organizationAPI } from '../api/organizationAPI';
import type { AppThunk } from 'src/redux/store';
import type { AxiosError } from 'axios';
import type { PortalOrganizationState } from 'src/@types/portalOrganization';

const initialState: PortalOrganizationState = {
  isLoading: false,
  portalOrganizations: [],
};

export const slice = createSlice({
  name: 'PortalOrganization',
  initialState,
  reducers: {
    resetPortalOrganizations(state: PortalOrganizationState): void {
      state.portalOrganizations = [];
    },
  },

  extraReducers: (builder): void => {
    builder
      // Get Portal Organizations
      // ----------------------------------------
      .addCase(getPortalOrganizations.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPortalOrganizations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.portalOrganizations = action.payload;
      })
      .addCase(getPortalOrganizations.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});
/**
 *
 * @return 結果
 */
export const resetPortalOrganizations =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.resetPortalOrganizations());
  };

export const getPortalOrganizations = createAsyncThunk('PortalOrganization/getPortalOrganizations', async (_, thunkAPI) => {
  try {
    const response = await organizationAPI.getOrganizations();
    return response.data;
  } catch (err) {
    const error = err as AxiosError | Error;
    const message: string = getAsyncErrorMessage(error);
    return thunkAPI.rejectWithValue(message);
  }
});

export default slice.reducer;
export const { reducer, actions } = slice;
