import UseAuth from 'src/hooks/useAuth';
import { excelBar } from 'src/features/excel/routes';
import { masterBar } from 'src/features/master/routes';
import { overviewBar } from 'src/features/overview/routes';
import { portalBar } from 'src/features/portal/routes';
import { USER_ROLES } from 'src/constants';
import i18n from 'src/locales/i18n';
import { PATH_SNOWFLAKE, PATH_PROXY } from 'src/routes/paths';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSnowflake } from '@fortawesome/free-regular-svg-icons';
import { faServer } from '@fortawesome/free-solid-svg-icons';

// サイドバーの描画
export const snowflakeBar = {
  subheader: i18n.t('sidemenu.snowflake.title'),
  items: [
    {
      title: i18n.t('sidemenu.snowflake.list'),
      path: PATH_SNOWFLAKE.root,
      icon: <FontAwesomeIcon icon={faSnowflake} size="lg" />,
    },
  ],
};

// サイドバーの描画
export const proxyBar = {
  subheader: 'プロキシ',
  items: [
    {
      title: 'プロキシタイムアウト対応',
      path: PATH_PROXY.root,
      icon: <FontAwesomeIcon icon={faServer} size="lg" />,
    },
  ],
};

// ----------------------------------------------------------------------

const sidebarConfigAdmin = [overviewBar, masterBar, excelBar, portalBar, snowflakeBar, proxyBar];
const sidebarConfigPoweruser = [overviewBar, masterBar, excelBar, portalBar, snowflakeBar, proxyBar];

const sidebarConfigUser = [overviewBar];
const sidebarConfigDefault = [overviewBar];

/**
 * ロールによってサイドバーのコンテンツを変更する
 * @return   サイドバーリスト
 */
const sidebarConfig = () => {
  const { user } = UseAuth();
  // console.log('user', user);
  if (user) {
    const role = user.role;
    const hasRolesArray: boolean = Array.isArray(role);
    if (hasRolesArray && role.includes(USER_ROLES.SCAFFOLD_ADMIN)) {
      return sidebarConfigAdmin;
    } else if (hasRolesArray && role.includes(USER_ROLES.SCAFFOLD_POWERUSER)) {
      return sidebarConfigPoweruser;
    } else if (hasRolesArray && role.includes(USER_ROLES.SCAFFOLD_USER)) {
      return sidebarConfigUser;
    } else {
      return sidebarConfigDefault;
    }
  } else {
    return sidebarConfigDefault;
  }
};

export default sidebarConfig;
