import { Theme } from '@mui/material/styles';

/**
 *
 * @param  theme テーマ
 * @return style
 */
export const Grid = (theme: Theme) => {
  return {
    MuiGrid: {
      styleOverrides: {},
    },
  };
};
