import { Theme } from '@mui/material/styles';

/**
 * スナックバー
 * @param  theme テーマ
 * @return style
 */
export const snackbar = (theme: Theme) => {
  return {
    MuiSnackbarContent: {
      styleOverrides: {
        root: {},
      },
    },
  };
};
