import { forwardRef } from 'react';
// material
import { alpha, useTheme } from '@mui/material/styles';
import { IconButtonProps } from '@mui/material';
//
import { IconButtonAnimate } from '../animate';

// ----------------------------------------------------------------------

interface MIconButtonProps extends Omit<IconButtonProps, 'color'> {
  color?: 'inherit' | 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';
}

const MIconButton = forwardRef<HTMLButtonElement, MIconButtonProps>(({ color = 'default', children, sx, ...other }, ref) => {
  const theme = useTheme();

  if (color === 'default' || color === 'inherit' || color === 'primary' || color === 'secondary') {
    return (
      // <IconButtonAnimate>
      //   <IconButton ref={ref} color={color} sx={sx} {...other}>
      //     {children}
      //   </IconButton>
      // </IconButtonAnimate>

      // IconButtonAnimateの中でIconButtonを使っているためbuttonが重なりワーニングが出る
      // これを回避するためIconButtonに渡しているpropsをIconButtonAnimateに渡すように書き換える
      <IconButtonAnimate ref={ref} color={color} sx={sx} {...other}>
        {children}
      </IconButtonAnimate>
    );
  }

  return (
    // <IconButtonAnimate>
    //   <IconButton
    //     ref={ref}
    //     sx={{
    //       color: theme.palette[color].main,
    //       '&:hover': {
    //         bgcolor: alpha(theme.palette[color].main, theme.palette.action.hoverOpacity),
    //       },
    //       ...sx,
    //     }}
    //     {...other}>
    //     {children}
    //   </IconButton>
    // </IconButtonAnimate>

    // IconButtonAnimateの中でIconButtonを使っているためbuttonが重なりワーニングが出る
    // これを回避するためIconButtonに渡しているpropsをIconButtonAnimateに渡すように書き換える
    <IconButtonAnimate
      ref={ref}
      sx={{
        color: theme.palette[color].main,
        '&:hover': {
          bgcolor: alpha(theme.palette[color].main, theme.palette.action.hoverOpacity),
        },
        ...sx,
      }}
      {...other}>
      {children}
    </IconButtonAnimate>
  );
});

export default MIconButton;
