import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import i18n from 'i18next';
import { itemAPI } from '../api/itemAPI';
import { EDIT_MODE, GENERAL_ERROR_MESSAGE } from 'src/constants';
import getAsyncErrorMessage from 'src/utils/getAsyncErrorMessage';
import type { AxiosError } from 'axios';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Item, CreateItemParams, GetItemParams, GetItemsParams, UpdateItemParams, DeleteItemParams, ItemState } from 'src/@types/item';
import type { RejectedWithValueAction } from 'src/@types/common';
import type { AppThunk, RootState } from 'src/redux/store';

export const initialState: ItemState = {
  isLoading: false,
  hasError: false,
  message: undefined,
  editMode: undefined,
  isDialogOpen: false,
  items: undefined,
  item: undefined,
  dateFrom: DateTime.now().startOf('month').minus({ months: 2 }).toUTC().toISO(),
  dateTo: DateTime.now().endOf('month').toUTC().toISO(),
};

const slice = createSlice({
  name: 'Item',
  initialState,
  reducers: {
    startLoading(state: ItemState): void {
      state.isLoading = true;
    },

    showMessage(state: ItemState, action: PayloadAction<string>): void {
      state.isLoading = false;
      state.hasError = false;
      state.message = action.payload;
    },

    hasError(state: ItemState, action: PayloadAction<string>): void {
      state.isLoading = false;
      state.hasError = true;
      state.message = action.payload;
    },

    resetMessage(state: ItemState): void {
      state.isLoading = false;
      state.hasError = false;
      state.message = undefined;
    },

    changeStartDate(state: ItemState, action: PayloadAction<string | undefined>): void {
      const dateFrom: string = action.payload ?? '';
      state.dateFrom = dateFrom;
    },

    changeEndDate(state: ItemState, action: PayloadAction<string | undefined>): void {
      const dateTo: string = action.payload ?? '';
      state.dateTo = dateTo;
    },

    openCreateDialog(state: ItemState): void {
      state.editMode = EDIT_MODE.CREATE;
      state.isDialogOpen = true;
    },

    openEditDialog(state: ItemState): void {
      state.editMode = EDIT_MODE.UPDATE;
      state.isDialogOpen = true;
    },

    openDeleteDialog(state: ItemState): void {
      state.editMode = EDIT_MODE.DELETE;
      state.isDialogOpen = true;
    },

    closeDialog(state: ItemState): void {
      state.isDialogOpen = false;
      state.editMode = undefined;
      state.item = undefined;
    },

    startCreateMode(state: ItemState): void {
      state.editMode = EDIT_MODE.CREATE;
    },

    startUpdateMode(state: ItemState): void {
      state.editMode = EDIT_MODE.UPDATE;
    },

    startDeleteMode(state: ItemState): void {
      state.editMode = EDIT_MODE.DELETE;
    },

    endMode(state: ItemState): void {
      state.editMode = undefined;
      state.item = undefined;
    },
  },

  extraReducers: (builder) => {
    builder
      // Create Item
      // ----------------------------------------
      .addCase(createItem.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.message = i18n.t('message.successfullyCreated');
        state.items = state.items === undefined ? [action.payload] : [...state.items, action.payload];
      })
      .addCase(createItem.rejected, (state, action) => {
        const rejectedWithValueAction = action as RejectedWithValueAction<CreateItemParams, string>;
        const message: string = rejectedWithValueAction.payload ?? action.error ?? GENERAL_ERROR_MESSAGE;
        state.isLoading = false;
        state.hasError = true;
        state.message = message;
      })
      // Get Item
      // ----------------------------------------
      .addCase(getItem.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.message = undefined;
        state.item = action.payload;
      })
      .addCase(getItem.rejected, (state, action) => {
        const rejectedWithValueAction = action as RejectedWithValueAction<GetItemParams, string>;
        const message: string = rejectedWithValueAction.payload ?? action.error ?? GENERAL_ERROR_MESSAGE;
        state.isLoading = false;
        state.hasError = true;
        state.message = message;
      })
      // Get Items
      // ----------------------------------------
      .addCase(getItems.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getItems.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.message = undefined;
        state.items = action.payload;
      })
      .addCase(getItems.rejected, (state, action) => {
        const rejectedWithValueAction = action as RejectedWithValueAction<undefined, string>;
        const message: string = rejectedWithValueAction.payload ?? action.error ?? GENERAL_ERROR_MESSAGE;
        state.isLoading = false;
        state.hasError = true;
        state.message = message;
      })
      // Update Item
      // ----------------------------------------
      .addCase(updateItem.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        // state.message = 'successfully updated';
        state.message = i18n.t('message.successfullyUpdated');
        const updatedItem: Item = action.payload;
        state.item = action.payload;
        state.items = state.items
          ? state.items.map((item) => {
              if (item.code === updatedItem.code) {
                return updatedItem;
              } else {
                return item;
              }
            })
          : [action.payload];
      })
      .addCase(updateItem.rejected, (state, action) => {
        const rejectedWithValueAction = action as RejectedWithValueAction<UpdateItemParams, string>;
        const message: string = rejectedWithValueAction.payload ?? action.error ?? GENERAL_ERROR_MESSAGE;
        state.isLoading = false;
        state.hasError = true;
        state.message = message;
      })
      // Delete Item
      // ----------------------------------------
      .addCase(deleteItem.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.message = i18n.t('message.successfullyDeleted');
        const deletedItem: Item = action.payload;
        state.item = undefined;
        state.items = state.items
          ? state.items.filter((item) => {
              if (item.code === deletedItem.code) {
                return false;
              } else {
                return true;
              }
            })
          : state.items;
      })
      .addCase(deleteItem.rejected, (state, action) => {
        const rejectedWithValueAction = action as RejectedWithValueAction<DeleteItemParams, string>;
        const message: string = rejectedWithValueAction.payload ?? action.error ?? GENERAL_ERROR_MESSAGE;
        state.isLoading = false;
        state.hasError = true;
        state.message = message;
      });
  },
});

/**
 *
 * @param  message メッセージ
 * @return void
 */
export const showMessage =
  (message: string): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.showMessage(message));
  };
/**
 *
 * @param  message メッセージ
 * @return void
 */
export const showError =
  (message: string): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.hasError(message));
  };
/**
 *
 * @return void
 */
export const resetMessage =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.resetMessage());
  };
/**
 *
 * @param  date: string | undefined
 * @return void
 */
export const changeStartDate =
  (date: string | undefined): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.changeStartDate(date));
  };
/**
 *
 * @param  date: string | undefined
 * @return void
 */
export const changeEndDate =
  (date: string | undefined): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.changeEndDate(date));
  };
/**
 *
 * @return 結果
 */
export const openCreateDialog =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.openCreateDialog());
  };
/**
 *
 * @return 結果
 */
export const openEditDialog =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.openEditDialog());
  };
/**
 *
 * @return 結果
 */
export const openDeleteDialog =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.openDeleteDialog());
  };
/**
 *
 * @return 結果
 */
export const closeDialog =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.closeDialog());
  };
/**
 *
 * @return 結果
 */
export const startCreateMode =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.startCreateMode());
  };
/**
 *
 * @return 結果
 */
export const startUpdateMode =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.startUpdateMode());
  };
/**
 *
 * @return 結果
 */
export const startDeleteMode =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.startDeleteMode());
  };
/**
 *
 * @return 結果
 */
export const endMode =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.endMode());
  };

export const getItems = createAsyncThunk<Item[], undefined, { state: RootState }>('Item/getItems', async (_, thunkAPI) => {
  try {
    const { item } = thunkAPI.getState();
    const params: GetItemsParams = { dateFrom: item.dateFrom, dateTo: item.dateTo };
    const response = await itemAPI.getItems(params);
    return response.data;
  } catch (err) {
    const message: string = getAsyncErrorMessage(err as AxiosError | Error);
    return thunkAPI.rejectWithValue(message);
  }
});

export const getItem = createAsyncThunk<Item, GetItemParams, {}>('Item/getItem', async (params, thunkAPI) => {
  try {
    const response = await itemAPI.getItem(params);
    return response.data;
  } catch (err) {
    const message: string = getAsyncErrorMessage(err as AxiosError | Error);
    return thunkAPI.rejectWithValue(message);
  }
});

export const createItem = createAsyncThunk<Item, CreateItemParams, {}>('Item/createItem', async (params, thunkAPI) => {
  try {
    const response = await itemAPI.createItem(params);
    return response.data;
  } catch (err) {
    const message: string = getAsyncErrorMessage(err as AxiosError | Error);
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateItem = createAsyncThunk<Item, UpdateItemParams, {}>('Item/updateItem', async (params, thunkAPI) => {
  try {
    const response = await itemAPI.updateItem(params);
    return response.data;
  } catch (err) {
    const message: string = getAsyncErrorMessage(err as AxiosError | Error);
    return thunkAPI.rejectWithValue(message);
  }
});

export const deleteItem = createAsyncThunk<Item, DeleteItemParams, {}>('Item/deleteItem', async (params, thunkAPI) => {
  try {
    const response = await itemAPI.deleteItem(params);
    return response.data;
  } catch (err) {
    const message: string = getAsyncErrorMessage(err as AxiosError | Error);
    return thunkAPI.rejectWithValue(message);
  }
});

export const { reducer } = slice;
