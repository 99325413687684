type ReactEnvName = 'local' | 'development' | 'staging' | 'production';
type NodeEnvName = 'development' | 'production' | 'test';
type EnvName = ReactEnvName | NodeEnvName;

interface Settings {
  projectRegion: string;
  identityPoolId: string;
  cognitoRegion: string;
  userPoolId: string;
  clientId: string;
  apiGateway: string;
  portalApi: string;
  redirectSignIn: string;
  redirectSignOut: string;
  cognitoDomain: string;
  aggridLicenceKey: string;
  googleAnalyticsId: string;
  useGa: boolean;
}
interface EnvSettings {
  local: Settings;
  development: Settings;
  staging: Settings;
  test: Settings;
  production: Settings;
}

export const currentEnv: EnvName = process.env.REACT_APP_ENV || process.env.NODE_ENV;

const envSettings: EnvSettings = {
  local: {
    projectRegion: 'ap-northeast-1',
    identityPoolId: 'ap-northeast-1:f963aa59-9173-4a09-897a-705a7f8e3b3b',
    cognitoRegion: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_foYGqFr8k',
    clientId: '6j6vibau5j6cp1itnfm2g8grce',
    apiGateway: 'http://localhost:3010',
    portalApi: 'https://datainsight-portal-server-development.toyotsu-digital.com/user/v1.1/userInfo',
    redirectSignIn: 'http://localhost:3000',
    redirectSignOut: 'http://localhost:3000',
    cognitoDomain: 'datainsight-pool-dev.auth.ap-northeast-1.amazoncognito.com',
    aggridLicenceKey:
      'Using_this_{AG_Grid}_Enterprise_key_{AG-050661}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Toyotsu_Syscom}_is_granted_a_{Multiple_Applications}_Developer_License_for_{15}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{15_November_2024}____[v3]_[01]_MTczMTYyODgwMDAwMA==c8c0a18b3636f327eee47079f2b25bc0',
    googleAnalyticsId: 'G-8QRB17T8CM',
    useGa: false,
  },
  development: {
    projectRegion: 'ap-northeast-1',
    identityPoolId: 'ap-northeast-1:f963aa59-9173-4a09-897a-705a7f8e3b3b',
    cognitoRegion: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_foYGqFr8k',
    clientId: '6j6vibau5j6cp1itnfm2g8grce',
    apiGateway: 'https://datainsight-scaffold-server-development.luna-mars-test.toyota-tsusho.com',
    portalApi: 'https://datainsight-portal-server-development.toyotsu-digital.com/user/v1.1/userInfo',
    redirectSignIn: 'https://datainsight-server-development.luna-mars-test.toyota-tsusho.com',
    redirectSignOut: 'https://datainsight-server-development.luna-mars-test.toyota-tsusho.com',
    cognitoDomain: 'datainsight-pool-dev.auth.ap-northeast-1.amazoncognito.com',
    aggridLicenceKey:
      'Using_this_{AG_Grid}_Enterprise_key_{AG-050661}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Toyotsu_Syscom}_is_granted_a_{Multiple_Applications}_Developer_License_for_{15}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{15_November_2024}____[v3]_[01]_MTczMTYyODgwMDAwMA==c8c0a18b3636f327eee47079f2b25bc0',
    googleAnalyticsId: '',
    useGa: false,
  },
  staging: {
    projectRegion: 'ap-northeast-1',
    identityPoolId: '',
    cognitoRegion: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_APA8bvSar',
    clientId: '1csku09hf52d11an14k8dfakis',
    apiGateway: 'https://elb-aws-sa-scaffold-stg-api-179718974.ap-northeast-1.elb.amazonaws.com',
    portalApi: 'https://portal-stg-server.datainsight-qas.toyota-tsusho.com/user/v1.1/userInfo',
    redirectSignIn: 'https://elb-aws-sa-scaffold-stg-front-934612394.ap-northeast-1.elb.amazonaws.com',
    redirectSignOut: 'https://elb-aws-sa-scaffold-stg-front-934612394.ap-northeast-1.elb.amazonaws.com',
    cognitoDomain: 'datainsight-pool-stg.auth.ap-northeast-1.amazoncognito.com',
    aggridLicenceKey:
      'Using_this_{AG_Grid}_Enterprise_key_{AG-050661}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Toyotsu_Syscom}_is_granted_a_{Multiple_Applications}_Developer_License_for_{15}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{15_November_2024}____[v3]_[01]_MTczMTYyODgwMDAwMA==c8c0a18b3636f327eee47079f2b25bc0',
    googleAnalyticsId: '',
    useGa: false,
  },
  test: {
    // same as staging for fallback
    projectRegion: 'ap-northeast-1',
    identityPoolId: 'ap-northeast-1:f963aa59-9173-4a09-897a-705a7f8e3b3b',
    cognitoRegion: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_foYGqFr8k',
    clientId: '6j6vibau5j6cp1itnfm2g8grce',
    apiGateway: 'http://localhost:3000',
    portalApi: 'https://datainsight-portal-server-development.toyotsu-digital.com/user/v1.1/userInfo',
    redirectSignIn: 'https://flour-staging.toyotsu-digital.com/',
    redirectSignOut: 'https://flour-staging.toyotsu-digital.com/',
    cognitoDomain: 'datainsight-pool-dev.auth.ap-northeast-1.amazoncognito.com',
    aggridLicenceKey:
      'Using_this_{AG_Grid}_Enterprise_key_{AG-050661}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Toyotsu_Syscom}_is_granted_a_{Multiple_Applications}_Developer_License_for_{15}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{15_November_2024}____[v3]_[01]_MTczMTYyODgwMDAwMA==c8c0a18b3636f327eee47079f2b25bc0',
    googleAnalyticsId: '',
    useGa: false,
  },
  production: {
    projectRegion: 'ap-northeast-1',
    identityPoolId: 'ap-northeast-1:f963aa59-9173-4a09-897a-705a7f8e3b3b',
    cognitoRegion: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_0zpIatPef',
    clientId: '3uvmvideth6ih2a3ejrbir7qpu',
    apiGateway: 'https://scaffold-server.datainsight.toyota-tsusho.com',
    portalApi: 'https://portal-server.datainsight.toyota-tsusho.com/user/v1.1/userInfo',
    redirectSignIn: 'https://scaffold.datainsight.toyota-tsusho.com',
    redirectSignOut: 'https://scaffold.datainsight.toyota-tsusho.com',
    cognitoDomain: 'datainsight-pool.auth.ap-northeast-1.amazoncognito.com',
    aggridLicenceKey:
      'Using_this_{AG_Grid}_Enterprise_key_{AG-050661}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Toyotsu_Syscom}_is_granted_a_{Multiple_Applications}_Developer_License_for_{15}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{15_November_2024}____[v3]_[01]_MTczMTYyODgwMDAwMA==c8c0a18b3636f327eee47079f2b25bc0',
    googleAnalyticsId: '',
    useGa: false,
  },
};
// console.log(envSettings[currentEnv]);

export const amplifyConfig = {
  aws_project_region: envSettings[currentEnv].projectRegion,
  aws_cognito_identity_pool_id: envSettings[currentEnv].identityPoolId,
  aws_cognito_region: envSettings[currentEnv].cognitoRegion,
  aws_user_pools_id: envSettings[currentEnv].userPoolId,
  aws_user_pools_web_client_id: envSettings[currentEnv].clientId,
  redirect_sign_in: envSettings[currentEnv].redirectSignIn,
  redirect_sign_out: envSettings[currentEnv].redirectSignOut,
  cognitoDomain: envSettings[currentEnv].cognitoDomain,
};

export const cognitoConfig = {
  userPoolId: envSettings[currentEnv].userPoolId,
  clientId: envSettings[currentEnv].clientId,
};

export const apiGateway = envSettings[currentEnv].apiGateway;
export const portalApi = envSettings[currentEnv].portalApi;
export const aggridLicenceKey = envSettings[currentEnv].aggridLicenceKey;
export const googleAnalyticsId = envSettings[currentEnv].googleAnalyticsId;
export const useGa = envSettings[currentEnv].useGa;

export const getOrganization = '/api/organization';
export const getUser = '/api/user';

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 50,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 40,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};
