import { useContext } from 'react';
import { CollapseDrawerContext } from '../contexts/CollapseDrawerContext';

// ----------------------------------------------------------------------
/**
 *
 * @return コンテキスト
 */
const useCollapseDrawer = () => useContext(CollapseDrawerContext);

export default useCollapseDrawer;
