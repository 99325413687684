import { useTheme } from '@mui/material/styles';
import type { Theme } from '@mui/material';

/**
 *
 * @param  isBalham Balham
 * @return テーマ
 */
const useAgGridTheme = (isBalham?: boolean): string => {
  const theme: Theme = useTheme();
  const themeName: string = isBalham ? 'ag-theme-balham' : 'ag-theme-alpine';
  const themeSuffix: string = theme.palette.mode === 'dark' ? '-dark' : '';
  const agGridTheme: string = themeName + themeSuffix;

  return agGridTheme;
};

export default useAgGridTheme;
