import { Navigate } from 'react-router-dom';
import { RoleBasedGuard } from 'src/guards/RoleBasedGuard';
import AuthGuardLayout from 'src/components-mars/layouts/AuthGuardLayout';
import { PATH_SNOWFLAKE } from '../paths';
import { USER_ROLES } from 'src/constants';
import SnowflakePage from 'src/pages/snowflake-page/SnowflakePage';

/**
 * snowflakeの関連ロール
 * Topメニューの可視制御などに使用する
 */
export const snowflakeRoute = {
  path: 'snowflake',
  element: <AuthGuardLayout />,
  children: [
    {
      path: '',
      element: <Navigate to={PATH_SNOWFLAKE.links} replace />,
    },
    {
      path: 'list', // ドキュメント検索
      element: (
        <RoleBasedGuard accessibleRoles={[USER_ROLES.SCAFFOLD_POWERUSER, USER_ROLES.SCAFFOLD_ADMIN]}>
          <SnowflakePage />
        </RoleBasedGuard>
      ),
    },
  ],
};
