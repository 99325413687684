import { Theme } from '@mui/material/styles';

/**
 * バッジ
 * @param  theme テーマ
 * @return style
 */
export const Badge = (theme: Theme) => {
  return {
    MuiBadge: {
      styleOverrides: {
        dot: {
          width: 10,
          height: 10,
          borderRadius: '50%',
        },
      },
    },
  };
};
