import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------
/**
 *
 * @param  theme テーマ
 * @return style
 */
export const Timeline = (theme: Theme) => {
  return {
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },

    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.divider,
        },
      },
    },
  };
};
