import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import { reducer as portalOrganizationReducer } from '../../slices/organization';
import { reducer as portalUserReducer } from '../../slices/user';
import { reducer as loginReducer } from './loginSlice';
import { reducer as itemReducer } from 'src/slices/item';
import { reducer as optionsReducer } from 'src/slices/options';
import { reducer as excelReducer } from 'src/slices/excel';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  portalOrganization: portalOrganizationReducer,
  portalUser: portalUserReducer,
  loginState: loginReducer,
  item: itemReducer,
  options: optionsReducer,
  excel: excelReducer,
});

export { rootPersistConfig, rootReducer };
