import { apiGateway, getOrganization } from 'src/config';
import axiosInstance from 'src/utils/axios';
import type { AxiosResponse } from 'axios';
import type { PortalOrganization } from 'src/@types/portalOrganization';

export const organizationAPI = {
  /**
   * ユーザ一覧の取得
   * @return 結果
   */
  getOrganizations() {
    return axiosInstance.get<undefined, AxiosResponse<PortalOrganization[]>>(apiGateway + getOrganization);
  },
};
