import { ReactNode } from 'react';
import { Container, Alert, AlertTitle } from '@mui/material';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  accessibleRoles: String[];
  children: ReactNode | string;
  admin?: Boolean;
};
/**
 *
 * @return ロール
 */
const useCurrentRole = () => {
  // Logic here to get current user role
  const { user } = useAuth();
  if (user) {
    const role: String[] = user['role'];
    return role;
  }
  return [''];
};
/**
 *
 * @return ロール
 */
const useCurrentRoleType = () => {
  const { user } = useAuth();
  if (user) {
    const roleType: String = user['authority'];
    return roleType;
  }
  return 'NONE';
};
/**
 *
 * @param  arr1 パラメータ1
 * @param  arr2 パラメータ2
 * @param  adminList 管理者リスト
 * @param  admin 管理者
 * @return  {boolean} 結果
 */
const roleCheck = (arr1: String[] = [], arr2: String[] = [], adminList: String, admin?: Boolean) => {
  const result = [...arr1, ...arr2].filter((item) => arr1.includes(item) && arr2.includes(item)).length > 0;
  if (admin) {
    if (!result) {
      return adminList === 'ADMIN';
    }
  }
  return result;
};

/**
 *
 * @param  param0 パラメータ
 * @return コンポーネント
 */
export const RoleBasedGuard = ({ accessibleRoles, children, admin }: RoleBasedGuardProp) => {
  const currentRole = useCurrentRole();
  const currentRoleType = useCurrentRoleType();

  if (!roleCheck(currentRole, accessibleRoles, currentRoleType, admin)) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
};
