import i18n from 'src/locales/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { PORTAL_PATH } from './portalPath';

// サイドバーの表示名
export const portalBar = {
  subheader: i18n.t('sidemenu.portal.title'),
  items: [
    {
      title: i18n.t('sidemenu.portal.organization'),
      path: PORTAL_PATH.ORGANIZATION,
      icon: <FontAwesomeIcon icon={faLink} size="lg" />,
    },
    {
      title: i18n.t('sidemenu.portal.user'),
      path: PORTAL_PATH.USER,
      icon: <FontAwesomeIcon icon={faLink} size="lg" />,
    },
  ],
};
