import i18n from 'src/locales/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import { MASTER_PATH } from './masterPath';

// サイドバーの描画
export const masterBar = {
  subheader: i18n.t('sidemenu.master.title'),
  items: [
    {
      title: i18n.t('sidemenu.master.item'),
      path: MASTER_PATH.ITEM,
      icon: <FontAwesomeIcon icon={faListCheck} size="lg" />,
    },
  ],
};
