import { Theme } from '@mui/material/styles';

/**
 *
 * @param  theme テーマ
 * @return style
 */
export const Breadcrumbs = (theme: Theme) => {
  return {
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(2),
        },
      },
    },
  };
};
