import { Theme } from '@mui/material/styles';

/**
 *
 * @param  theme テーマ
 * @return style
 */
export const Pickers = (theme: Theme) => {
  return {};
};
