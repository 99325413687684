import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { PATH_DASHBOARD, PATH_OVERVIEW } from './routes/paths';
import type { UserMenuOptions } from 'src/@types/layouts';

// ----------------------------------------------------------------------

export const INITIAL_PATH = PATH_OVERVIEW.root;

export const GENERAL_ERROR_MESSAGE: string = 'Error Occurred';
export const GENERAL_SUCCESS_MESSAGE: string = 'Success';

export const TOOLTIP_SHOW_DELAY: number = 0;

export const USER_MENU_OPTIONS: UserMenuOptions = [
  {
    label: 'Settings',
    icon: settings2Fill,
    linkTo: PATH_DASHBOARD.account,
  },
];

export const AGGRID_ROWS_PER_PAGE = [
  { value: 10, label: '10 rows/page' },
  { value: 20, label: '20 rows/page' },
  { value: 30, label: '30 rows/page' },
];

export const EDIT_MODE = {
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
} as const;

export const USER_ROLES = {
  SCAFFOLD_ADMIN: 'scaffold-admin',
  SCAFFOLD_POWERUSER: 'scaffold-poweruser',
  SCAFFOLD_USER: 'scaffold-user',
} as const;

export const ITEM_UNIT = {
  BAG: 'BAG',
  FCL: 'FCL',
} as const;
export const ORDARABLE_UNIT_OPTIONS = [
  { label: ITEM_UNIT.BAG, value: ITEM_UNIT.BAG },
  { label: ITEM_UNIT.FCL, value: ITEM_UNIT.FCL },
] as const;

export const MONTHS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
