import { Theme } from '@mui/material/styles';

/**
 *
 * @param  theme テーマ
 * @return style
 */
export const Autocomplete = (theme: Theme) => {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.z20,
        },
      },
    },
  };
};
