import { apiGateway } from 'src/config';
import axiosInstance from 'src/utils/axios';

// アップロードしたExcelからデータを取り出す関数に、Excelを投げる
export const excelAPI = {
  uploadExcel(values: FormData) {
    return axiosInstance.post(apiGateway + '/upload/excel', values); // 修正
  },
  downloadSample() {
    return axiosInstance.post(apiGateway + '/upload/sample'); // 修正
  },
};
