import { Navigate } from 'react-router-dom';
import { lazy } from 'react';
import { RoleBasedGuard } from 'src/guards/RoleBasedGuard';
import AuthGuardLayout from 'src/components-mars/layouts/AuthGuardLayout';
import { loadingComponent } from 'src/components-mars/effect/LoadingComponent';
import { USER_ROLES } from 'src/constants';

import { OVERVIEW_PATH } from './overviewPath';

const Links = loadingComponent(lazy(() => import('src/pages/overview-page/OverviewPage')));

export const overviewRoute = {
  path: 'overview',
  element: <AuthGuardLayout />,
  children: [
    {
      path: '',
      element: <Navigate to={OVERVIEW_PATH.LINKS} replace />,
    },
    {
      path: 'links',
      children: [
        {
          path: '',
          element: (
            <RoleBasedGuard accessibleRoles={[USER_ROLES.SCAFFOLD_USER, USER_ROLES.SCAFFOLD_POWERUSER, USER_ROLES.SCAFFOLD_ADMIN]}>
              <Links />
            </RoleBasedGuard>
          ),
        },
      ],
    },
  ],
};
