import { apiGateway } from 'src/config';
import axiosInstance from 'src/utils/axios';
import API_PATH from 'src/api/endpoints';
import type { AxiosResponse } from 'axios';
import type { Factory } from 'src/@types/options';

export const optionsAPI = {
  /**
   * 工場一覧の取得
   * @return Promise<AxiosResponse<Factory[]>>
   */
  getOptionsFactories(): Promise<AxiosResponse<Factory[]>> {
    return axiosInstance.get(apiGateway + API_PATH.OPTIONS.FACTORY);
  },
};

export default optionsAPI;
