import { Grid, TextField, Typography } from '@mui/material';
import React from 'react';

interface SnowflakeSettingInputProps {
  title: string;
  palceholder: string;
  value: string | null;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  error?: boolean;
  helperText?: string;
}

export const SnowflakeSettingInput = React.memo(({ title, palceholder, value, onChange, error, helperText }: SnowflakeSettingInputProps) => {
  return (
    <Grid container direction={'row'} spacing={1} xs={12} mt={2}>
      <Grid item xs={3} alignContent={'center'}>
        <Typography>{title}</Typography>
      </Grid>
      <Grid item xs={9}>
        <TextField placeholder={palceholder} value={value} onChange={onChange} size="small" fullWidth error={error} helperText={helperText} />
      </Grid>
    </Grid>
  );
});
