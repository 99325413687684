import { Navigate } from 'react-router-dom';
import AuthGuardLayout from 'src/components-mars/layouts/AuthGuardLayout';

import { PORTAL_PATH } from './portalPath';
import { RoleBasedGuard } from 'src/guards/RoleBasedGuard';
import { USER_ROLES } from 'src/constants';
import { loadingComponent } from 'src/components-mars/effect/LoadingComponent';
import { lazy } from 'react';

const Organization = loadingComponent(lazy(() => import('src/pages/portal-org-page/PortalOrgPage')));
const User = loadingComponent(lazy(() => import('src/pages/portal-user-page/PortalUserPage')));

export const portalRoute = {
  path: 'portal',
  element: <AuthGuardLayout />,
  children: [
    {
      path: '',
      element: <Navigate to={PORTAL_PATH.ORGANIZATION} replace />,
    },
    {
      path: 'organization',
      children: [
        {
          path: '',
          element: (
            // ユーザのロールを確認し、ページを表示するかどうか決める
            <RoleBasedGuard accessibleRoles={[USER_ROLES.SCAFFOLD_ADMIN]}>
              <Organization />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    {
      path: 'user',
      children: [
        {
          path: '',
          element: (
            // ユーザのロールを確認し、ページを表示するかどうか決める
            <RoleBasedGuard accessibleRoles={['scaffold-admin']} admin={true}>
              <User />
            </RoleBasedGuard>
          ),
        },
      ],
    },
  ],
};
