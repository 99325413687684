// routes
import { Router } from 'src/routes';
// theme
import { ThemeProvider } from 'src/theme';
// hooks
import useAuth from 'src/hooks/useAuth';
// components
import { Settings } from 'src/components-minimals/settings';
import { RtlLayout } from 'src/components-mars/layouts/RtlLayout';
import { ScrollToTop } from 'src/components-minimals/ScrollToTop';
import { LoadingScreen } from 'src/components-minimals/LoadingScreen';
import { NotistackProvider } from 'src/components-minimals/NotistackProvider';
import { ThemePrimaryColor } from 'src/components-minimals/ThemePrimaryColor';
import { LicenseManager } from 'ag-grid-enterprise';
import { aggridLicenceKey, googleAnalyticsId, useGa } from 'src/config';
import ReactGA from 'react-ga4';
/**
 * @return  コンポーネント
 */
const App = () => {
  LicenseManager.setLicenseKey(aggridLicenceKey);

  const { isInitialized } = useAuth();
  // Google Analyticsの解析を行うか
  if (useGa) {
    ReactGA.initialize(googleAnalyticsId);
    console.log();
  }

  return (
    <ThemeProvider>
      <ThemePrimaryColor>
        <RtlLayout>
          <NotistackProvider>
            <Settings />
            <ScrollToTop />
            {isInitialized ? <Router /> : <LoadingScreen />}
          </NotistackProvider>
        </RtlLayout>
      </ThemePrimaryColor>
    </ThemeProvider>
  );
};
export default App;
