import { Navigate } from 'react-router-dom';
import AuthGuardLayout from 'src/components-mars/layouts/AuthGuardLayout';

import { RoleBasedGuard } from 'src/guards/RoleBasedGuard';
import { USER_ROLES } from 'src/constants';
import { PATH_PROXY } from '../paths';
import ProxyTimeoutPage from 'src/pages/proxy-timeout-page/ProxyTimeoutPage';

export const proxyRoute = {
  path: 'proxy',
  element: <AuthGuardLayout />,
  children: [
    {
      path: '',
      element: <Navigate to={PATH_PROXY.timeout} replace />,
    },
    {
      path: 'timeout',
      children: [
        {
          path: '',
          element: (
            <RoleBasedGuard accessibleRoles={[USER_ROLES.SCAFFOLD_POWERUSER, USER_ROLES.SCAFFOLD_ADMIN]}>
              <ProxyTimeoutPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
  ],
};
