import React from 'react';
import { Button, Container, Divider, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Page } from 'src/components-minimals/Page';
import { HeaderBreadcrumbs } from 'src/components-minimals/HeaderBreadcrumbs';
import { PATH_SNOWFLAKE } from 'src/routes/paths';
import { useSnowflakePageModel } from './useSnowflakePageModel';
import { SnowflakeSettingInput } from 'src/components/SnowflakeSettingInput';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Form, FormikProvider } from 'formik';

const SnowflakePage = (): React.ReactElement => {
  const pm = useSnowflakePageModel();
  return (
    <Page title={'Snowflake'}>
      <Container maxWidth="xl">
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <HeaderBreadcrumbs
              heading={'Snowflake'}
              links={[
                { name: 'snowflake', href: PATH_SNOWFLAKE.root },
                { name: 'snowflake list', href: PATH_SNOWFLAKE.links },
              ]}
            />
          </Grid>
        </Grid>
        <Stack direction={'row'} alignItems="center" spacing={2}>
          <Grid container direction={'column'}>
            <Typography>Snowflake setting</Typography>
            <FormikProvider value={pm.formik}>
              <Form autoComplete="off" noValidate onSubmit={pm.handleSubmit}>
                <SnowflakeSettingInput
                  title="アカウント"
                  palceholder="example123.ap-northeast-1.aws"
                  value={pm.account}
                  onChange={(e) => pm.setAccount(e.target.value)}
                  error={Boolean(pm.touched.account && pm.errors.account)}
                  helperText={pm.touched.account ? pm.errors.account : undefined}
                />
                <SnowflakeSettingInput
                  title="ユーザー名"
                  palceholder="example123"
                  value={pm.username}
                  onChange={(e) => pm.setUsername(e.target.value)}
                  error={Boolean(pm.touched.username && pm.errors.username)}
                  helperText={pm.touched.username ? pm.errors.username : undefined}
                />
                <Grid container direction={'row'} spacing={1} xs={12} mt={2}>
                  <Grid item xs={3} alignContent={'center'}>
                    <Typography>パスワード</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      value={pm.password}
                      onChange={(e) => pm.setPassword(e.target.value)}
                      size="small"
                      fullWidth
                      type={pm.showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={pm.handleShowPassword} edge="end">
                              <Icon icon={pm.showPassword ? eyeFill : eyeOffFill} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={Boolean(pm.touched.password && pm.errors.password)}
                      helperText={pm.touched.password ? pm.errors.password : undefined}
                    />
                  </Grid>
                </Grid>
                <SnowflakeSettingInput
                  title="WAREHOUSE"
                  palceholder="EXAMPLE_WH"
                  value={pm.warehouse}
                  onChange={(e) => pm.setWarehouse(e.target.value)}
                />
                <SnowflakeSettingInput
                  title="ROLE"
                  palceholder="example_scaffold_role"
                  value={pm.role}
                  onChange={(e) => pm.setRole(e.target.value)}
                />
                <SnowflakeSettingInput
                  title="データベース"
                  palceholder="SNOWFLAKE"
                  value={pm.database}
                  onChange={(e) => pm.setDatabase(e.target.value)}
                />
                <SnowflakeSettingInput title="スキーマ" palceholder="Public" value={pm.schema} onChange={(e) => pm.setSchema(e.target.value)} />
                <SnowflakeSettingInput
                  title="テーブル名"
                  palceholder="EXAMPLE_TABLE"
                  value={pm.table}
                  onChange={(e) => pm.setTable(e.target.value)}
                />
                <Grid mt={2}>
                  <Button color="primary" variant="contained" type="submit" fullWidth>
                    GET Table's Datas
                  </Button>
                </Grid>
              </Form>
            </FormikProvider>
          </Grid>
          <Divider orientation="vertical" flexItem color="black" />
          <Grid container>
            <Grid container direction={'column'}>
              <Typography>{pm.table}テーブルデータ</Typography>
              <Grid container style={{ height: '65vh', overflow: 'auto' }} mt={1} border={1}>
                {pm.datas.map((row, index) => (
                  <Grid item key={index} xs={12}>
                    <pre style={{ fontSize: '12px' }}>{JSON.stringify(row, null, 2)}</pre>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Page>
  );
};
export default SnowflakePage;
